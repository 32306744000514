// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../lib/litvplayerv2/LiTVPlayer.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!./channel/watchMore.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ppn-display-none {
  display: none;
}
.ppn-absolute {
  position: absolute;
}
.ppn-bg-000 {
  background: #000;
}
.ppn-top-0 {
  top: 0;
}
.ppn-w-100p {
  width: 100%;
}
.ppn-w-150px {
  width: 150px;
}
.ppn-mg-auto {
  margin: 0 auto;
}
.ppn-pd-tb-1em {
  padding-top: 1em;
  padding-bottom: 1em;
}
.ppn-text-center {
  text-align: center;
}
.ppn-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ppn-subtitle {
  color: #fff;
  font-size: 17px;
  font-weight: 400;
}
.ppn-c-ddd {
  color: #ddd;
}
.ppn-font-w-400 {
  font-weight: 400;
}
.ppn-font-s-13px {
  font-size: 13px;
}
.ppn-button {
  display: block;
  color: #fff;
  text-align: center;
  height: 29px;
  line-height: 29px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  text-shadow: 0 0 1px #f23b00;
  border: 1px solid #ca5500;
  box-sizing: border-box;
  background-image: linear-gradient(0deg, #ff5d00 0, #eba500 100%);
}
.ppn-button:hover {
  box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.3), inset 0 2px 5px 0 rgba(0, 0, 0, 0.2);
  background: #f58100;
  padding-top: 1px;
}
.ppn-ratio-16-9::before {
  content: "";
  height: 0;
  padding-top: 56.25%;
  display: block;
}
.linear_ad_impression .ppn-recommend {
  display: none;
}
.ppn-recommend {
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 100%;
  z-index: 3;
  font-size: 12px;
  transition: transform 0.25s linear;
}
.ppn-recommend .ppn-recommend_tab {
  position: absolute;
  top: 50%;
  left: -46px;
  width: 46px;
  height: 176px;
  color: #ddd;
  padding: 32px 0 0 12px;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.7);
  border: solid 1.8px rgba(0, 0, 0, 0.7);
  border-radius: 28.8px 0 0 28.8px;
  cursor: pointer;
  line-height: 28px;
  font-size: 24px;
  font-weight: 500;
}
.ppn-recommend .ppn-recommend_tab::before {
  content: "\\63a8\\85a6\\6e05\\55ae";
  /* 推薦清單 */
  display: block;
}
.ppn-recommend .ppn-recommend_tab:hover {
  color: #fff;
}
.is-tiny .ppn-recommend {
  display: none;
}
.is-small .ppn-recommend .ppn-recommend_tab {
  left: -20px;
  width: 20px;
  height: 76px;
  border: solid 0.8px rgba(0, 0, 0, 0.7);
  border-radius: 12.5px 0 0 12.5px;
  padding: 14px 0 0 4px;
  font-size: 11px;
  line-height: 12px;
}
.is-small .ppn-recommend .ppn-list_container {
  width: 252px;
  padding-left: 4px;
}
.is-small .ppn-recommend .ppn-list_container .header {
  font-size: 13px;
  line-height: 13px;
  margin: 12px 0 5px 8px;
}
.is-small .ppn-recommend .ppn-list_container > * {
  width: 252px;
  padding: 0;
}
.is-small .ppn-recommend .ppn-list_container ul {
  width: 243px;
  height: calc(100% - 42px);
}
.is-small .ppn-recommend .ppn-list_container a {
  height: 44px;
  padding: 0;
}
.is-small .ppn-recommend .ppn-list_container .ppn-text {
  width: 220px;
  margin: 8px 0 0 8px;
}
.is-small .ppn-recommend .ppn-list_container .ppn-pl-item-title {
  font-size: 13px;
  line-height: 13px;
}
.is-small .ppn-recommend .ppn-list_container .ppn-display_count {
  font-size: 11px;
}
.is-small .ppn-recommend .ppn-recommend_photo {
  display: none;
}
.is-small .ppn-recommend.ppn-open .ppn-recommend_tab {
  top: 12px;
  left: 292px;
  width: 16px;
  height: 16px;
  background: linear-gradient(to bottom, transparent 7.5px, white 7.5px, white 8.5px, transparent 8.5px), linear-gradient(to right, transparent 7.5px, white 7.5px, white 8.5px, transparent 8.5px);
}
.ppn-recommend.ppn-open {
  transform: translateX(-320px);
}
.ppn-recommend.ppn-open .ppn-recommend_tab {
  left: 286px;
  top: 11px;
  width: 20px;
  height: 20px;
  z-index: 1;
  padding: 0;
  background: linear-gradient(to bottom, transparent 9.5px, white 9.5px, white 10.5px, transparent 10.5px), linear-gradient(to right, transparent 9.5px, white 9.5px, white 10.5px, transparent 10.5px);
  transform: rotate(45deg);
  border: unset;
}
.ppn-recommend.ppn-open .ppn-recommend_tab::before {
  display: none;
}
.ppn-recommend.ppn-open .ppn-recommend_tab::after {
  transform: translateY(-50%);
}
.ppn-recommend .ppn-list_container {
  width: 320px;
  position: absolute;
  top: 0;
  right: -320px;
  height: 100%;
  background: rgba(0, 0, 0, 0.65);
  padding-left: 8px;
}
.ppn-recommend .ppn-list_container > * {
  width: 320px;
  padding: 0;
}
.ppn-recommend .ppn-list_container ul {
  width: 307px;
  height: calc(100% - 42px);
  overflow-x: hidden;
  overflow-y: scroll;
  list-style-type: none;
}
.ppn-recommend .ppn-list_container ul::-webkit-scrollbar {
  width: 8px;
}
.ppn-recommend .ppn-list_container ul::-webkit-scrollbar-thumb {
  background-color: #5f5f5f;
  border-radius: 4px;
}
.ppn-recommend .ppn-list_container ul::-webkit-scrollbar-track {
  margin: 10px 0;
}
.ppn-recommend .header {
  position: relative;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  margin: 15px 0 9px 10px;
}
.ppn-recommend .header::before {
  content: "\\63a8\\85a6\\6e05\\55ae";
  /* 推薦清單 */
  font-family: LiTViconFont;
  text-decoration: none!important;
  color: #fff;
}
.ppn-recommend .ppn-recommend_item {
  position: relative;
}
.ppn-recommend .ppn-recommend_item a {
  text-decoration: none;
  width: 299px;
  height: 56px;
  padding: 6px 0 0 8px;
  overflow: hidden;
  position: relative;
  display: block;
}
.ppn-recommend .ppn-recommend_item .ppn-recommend_photo {
  width: 80px;
  height: 45px;
  float: left;
  background-color: black;
}
.ppn-recommend .ppn-recommend_item .ppn-recommend_photo img {
  width: 100%;
  height: 100%;
}
.ppn-recommend .ppn-recommend_item .ppn-text {
  width: 195px;
  float: left;
  text-align: left;
  margin-left: 8px;
  margin-top: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ppn-recommend .ppn-recommend_item .ppn-pl-item-title {
  color: #e0e0e0;
  font-size: 15px;
  font-weight: 500;
  line-height: 19px;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ppn-recommend .ppn-recommend_item .ppn-display_count {
  color: #919191;
  font-size: 13px;
  line-height: 26px;
}
.ppn-recommend .ppn-recommend_item:hover img {
  opacity: 0.5;
}
.ppn-recommend .ppn-recommend_item:hover .ppn-playlist-playicon {
  display: block;
  top: 15px;
  left: 35px;
}
.ppn-recommend .ppn-recommend_item:hover .ppn-text {
  opacity: 0.75;
}
.ppn-only_fullscreen {
  display: none;
}
.vjs-fullscreen .ppn-only_fullscreen {
  display: block;
}
.ppn-showcase {
  display: none;
  width: 100%;
  background-color: #f1f1f1;
  position: relative;
  font-family: Microsoft JhengHei, Heiti TC, WenQuanYi Zen Hei, Arial;
}
.ppn-ancillary.measured .ppn-showcase {
  display: grid;
  grid: 1fr 20px / 1fr;
  align-items: center;
}
.ppn-showcase * {
  box-sizing: border-box;
}
.ppn-showcase a {
  text-decoration: none;
}
.ppn-showcase_content_wrapper {
  overflow: hidden;
}
.ppn-showcase_content {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  transition: transform 0.5s;
}
.ppn-showcase_item {
  display: flex;
  width: 100%;
  border-bottom: dashed 1px #cecece;
}
.ppn-showcase_item.empty_item,
.ppn-showcase_item:nth-child(4n + 4) {
  border-bottom: none;
}
.ppn-showcase_item a {
  display: inline-flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
}
.ppn-show-photo {
  position: relative;
  background-color: black;
}
.ppn-show-photo img {
  width: 100%;
  height: 100%;
}
.ppn-showcase_item:hover .ppn-show-photo img {
  opacity: 0.5;
}
.ppn-showcase_item:hover .ppn-show-photo .ppn-playlist-playicon {
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ppn-show-article {
  padding-left: 8px;
  overflow: hidden;
}
.ppn-show-article h2,
.ppn-show-article p {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ppn-show-article h2 {
  color: #000;
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  margin: 0px 0 4px 0;
  font-family: PingFangTC;
}
.ppn-show-article p {
  color: #666666;
  font-size: 13px;
  line-height: 16px;
  margin: 0;
}
.ppn-showcase_page_icon {
  display: flex;
  justify-content: center;
}
.ppn-showcase_page_icon a {
  background-color: #8f8f8f;
  border-radius: 10px;
  width: 20px;
  height: 20px;
  border: solid 7px #f1f1f1;
  cursor: pointer;
}
.ppn-showcase_page_icon a.ppn-page_focus {
  background-color: #8711aa;
  cursor: default;
}
.ppn-order-1 {
  order: -1;
}
.ppn-ancillary.over-360 .ppn-showcase {
  padding: 4px 16px 0 16px;
}
.ppn-ancillary.over-360 .ppn-showcase_content {
  height: 280px;
}
.ppn-ancillary.over-360 .ppn-showcase_item {
  height: 70px;
}
.ppn-ancillary.over-360 .ppn-show-photo {
  width: 92.4px;
  height: 52px;
  flex: 0 0 92.4px;
  margin: 0 0 0 12px;
}
.ppn-ancillary.over-360 .ppn-show-article h2 {
  line-height: 25px;
}
.ppn-container[max-width~='729px']:not(.ppn-overflows) .ppn-has_ancillary .ppn-ancillary.less-360 .ppn-showcase {
  padding: 8px 8px 0 8px;
}
.ppn-container[max-width~='729px']:not(.ppn-overflows) .ppn-has_ancillary .ppn-ancillary.less-360 .ppn-showcase_content {
  height: 232px;
}
.ppn-container[max-width~='729px']:not(.ppn-overflows) .ppn-has_ancillary .ppn-ancillary.less-360 .ppn-showcase_item {
  height: 58px;
}
.ppn-container[max-width~='729px']:not(.ppn-overflows) .ppn-has_ancillary .ppn-ancillary.less-360 .ppn-show-photo {
  width: 71.11px;
  height: 40px;
  flex: 0 0 71.11px;
  margin: 0;
}
.ppn-container[min-width~='730px']:not(.ppn-overflows) .ppn-has_ancillary .ppn-ancillary.less-360 .ppn-showcase {
  padding: 0 16px;
}
.ppn-container[min-width~='730px']:not(.ppn-overflows) .ppn-has_ancillary .ppn-ancillary.less-360 .ppn-showcase_content {
  height: 260px;
}
.ppn-container[min-width~='730px']:not(.ppn-overflows) .ppn-has_ancillary .ppn-ancillary.less-360 .ppn-showcase_item {
  height: 65px;
}
.ppn-container[min-width~='730px']:not(.ppn-overflows) .ppn-has_ancillary .ppn-ancillary.less-360 .ppn-show-photo {
  width: 87.1px;
  height: 49px;
  flex: 0 0 87.1px;
  margin: 0 0 0 12px;
}
.ppn-container[min-width~='730px']:not(.ppn-overflows) .ppn-has_ancillary .ppn-ancillary.less-300 .ppn-showcase {
  padding: 4px 8px 0 8px;
}
.ppn-container[min-width~='730px']:not(.ppn-overflows) .ppn-has_ancillary .ppn-ancillary.less-300 .ppn-showcase_content {
  height: 232px;
}
.ppn-container[min-width~='730px']:not(.ppn-overflows) .ppn-has_ancillary .ppn-ancillary.less-300 .ppn-showcase_item {
  height: 58px;
}
.ppn-container[min-width~='730px']:not(.ppn-overflows) .ppn-has_ancillary .ppn-ancillary.less-300 .ppn-show-photo {
  width: 71.11px;
  height: 40px;
  flex: 0 0 71.11px;
  margin: 0;
}
.ppn-companionAd {
  display: none;
  width: 300px;
  height: 250px;
}
.ppn-companionAd.ppn-show {
  display: block;
}
.ppn-ancillary.less-300 .ppn-companionAd.ppn-show {
  transform: scale(0.75);
}
.ppn-ancillary:not(.ppn-loaded) .ppn-lds-columnar {
  position: absolute;
  width: 80px;
  height: 80px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ppn-ancillary:not(.ppn-loaded) .ppn-lds-columnar div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #fff;
  animation: ppn-lds-columnar 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.ppn-ancillary:not(.ppn-loaded) .ppn-lds-columnar div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.ppn-ancillary:not(.ppn-loaded) .ppn-lds-columnar div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.ppn-ancillary:not(.ppn-loaded) .ppn-lds-columnar div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes pn-lds-columnar {
  0% {
    top: 8px;
    height: 64px;
  }
  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}
.ppn-gpt_hosting {
  display: flex;
  justify-content: space-evenly;
  align-content: space-evenly;
  flex-wrap: wrap;
}
.litv-player .countdown .ltp_countdown_count {
  margin: 0;
}
.litv-player .video-js .vjs-control-bar,
.litv-player .video-js .vjs-control-bar .vjs-control,
.litv-player .video-js .ad-control .vjs-control,
.litv-player .video-js .vjs-control span {
  font-size: inherit;
}
.litv-player.is-fullscreen.straight .logo_container .c_image.b {
  transform: none !important;
}
body.vjs-full-window .litv-player.is-fullscreen {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ppn-player_main.is-desktop.is-tiny .vjs-menu-content {
  width: 56px;
  left: calc(50% - 28px);
  padding: 6px 0;
}
.ppn-player_main.is-desktop.is-tiny .vjs-menu li {
  font-size: 12px;
  padding: 0;
}
.ppn-player_main.is-desktop.less-300 {
  font-size: 10px !important;
}
.ppn-player_main.is-desktop.less-300 .vjs-time-control {
  margin: 0 1px !important;
}
.ppn-player_main.is-desktop.less-300 .vjs-button,
.ppn-player_main.is-desktop.less-300 .vjs-volume-panel {
  width: 2em !important;
}
.ppn-player_main.is-desktop.less-300 .vjs-button .vjs-icon-placeholder:before,
.ppn-player_main.is-desktop.less-300 .vjs-volume-panel .vjs-icon-placeholder:before {
  font-size: 1em !important;
  line-height: 3em !important;
}
.ppn-player_main.is-desktop.less-300 .vjs-button.vjs-quality-level {
  width: 3em !important;
}
.ppn-player_main.litv-player .vjs-live-icon:before {
  margin: 0 0.5em;
}
.ppn-player_main.litv-player .vjs-live-icon::after {
  font-size: 1em;
}
.ppn-player_main.litv-player .vjs-caption {
  display: block;
  max-width: 80%;
  overflow: hidden;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.7);
}
.ppn-player_main.litv-player .vjs-caption p.caption-title {
  font-size: 20px;
  line-height: 28px;
}
.ppn-player_main.litv-player .vjs-caption p.caption-subtitle {
  display: none;
}
.ppn-player_main.litv-player.less-480 .vjs-caption p.caption-title {
  font-size: 14px;
  line-height: 19.6px;
}
.ppn-player_main.litv-player.less-480 .vjs-control-bar {
  padding: 0 12px;
  align-items: center;
}
.ppn-player_main.litv-player.less-480 .vjs-control-bar .vjs-control {
  font-size: 12px;
}
.ppn-drag_area {
  cursor: move;
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: #000;
  border-radius: 4px 4px 0 0;
  top: -30px;
  right: 0;
  height: 30px;
  line-height: 30px;
  padding: 0 14px;
}
.ppn-drag_area svg {
  display: inline;
  user-select: none;
}
.ppn-container.ppn-overflows .ppn-drag_area {
  display: flex;
}
.ppn-content[data-epg-page='channel'] .channel-list-wrapper {
  display: flex;
}
.ppn-container .channel-list-wrapper {
  display: none;
  position: absolute;
  flex-direction: column;
  align-items: center;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  color: white;
  background: #111;
  user-select: none;
}
.ppn-container .channel-list-wrapper .menu-header::before {
  content: "頻道列表";
}
.ppn-container .channel-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
}
.ppn-container .channel-list::-webkit-scrollbar {
  width: 4px;
}
.ppn-container .channel-list::-webkit-scrollbar-thumb {
  background-color: grey;
  border-radius: 4px;
}
.ppn-container .channel-list .item {
  width: 100%;
  flex-shrink: 0;
  cursor: pointer;
  padding: 0 8px;
  box-sizing: border-box;
  font-size: 16px;
  color: #fff;
}
.ppn-container .channel-list .item div {
  display: flex;
  align-items: center;
  gap: 16px;
  height: 52px;
  border-bottom: solid 1px #666;
  box-sizing: border-box;
}
.ppn-container .channel-list .item:last-of-type div {
  border-bottom: none;
}
.ppn-container .channel-list .item img {
  width: 60px;
  height: 100%;
  object-fit: contain;
}
.ppn-container .channel-list .item span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ppn-container .channel-list .item svg {
  margin-left: auto;
  flex-shrink: 0;
  fill: white;
}
.ppn-container .channel-list .item svg:hover {
  fill: var(--brand-color);
}
.litv-player.less-480 .ppn-epg-float-anchor .channel-list-wrapper.reach-top .channel-scroll:first-of-type,
.litv-player.less-480 .ppn-epg-float-anchor .channel-list-wrapper.reach-bottom .channel-scroll:last-of-type {
  pointer-events: none;
}
.litv-player.less-480 .ppn-epg-float-anchor .channel-list-wrapper.reach-top .channel-scroll:first-of-type .arrow,
.litv-player.less-480 .ppn-epg-float-anchor .channel-list-wrapper.reach-bottom .channel-scroll:last-of-type .arrow {
  opacity: 0;
}
.litv-player.less-480 .ppn-epg-float-anchor .channel-list-wrapper .menu-header {
  display: none;
}
.litv-player.less-480 .ppn-epg-float-anchor .channel-list-wrapper .channel-scroll {
  cursor: pointer;
  position: absolute;
  left: 0;
  width: 100%;
  height: 20px;
  padding: 2px;
  box-sizing: border-box;
  background: linear-gradient(to bottom, #111 19.5%, rgba(16, 16, 16, 0.838053) 53.5%, rgba(16, 16, 16, 0.551946) 71.5%, rgba(16, 16, 16, 0.234234) 83.5%, transparent 100%);
}
.litv-player.less-480 .ppn-epg-float-anchor .channel-list-wrapper .channel-scroll:first-of-type {
  top: 0;
}
.litv-player.less-480 .ppn-epg-float-anchor .channel-list-wrapper .channel-scroll:last-of-type {
  bottom: 0;
  transform: rotate(180deg);
}
.litv-player.less-480 .ppn-epg-float-anchor .channel-list {
  padding: 20px 0;
}
.litv-player.less-480 .ppn-epg-float-anchor .channel-list::-webkit-scrollbar {
  display: none;
}
.litv-player.less-480 .ppn-epg-float-anchor .channel-list .item {
  padding: 0 4px;
}
.litv-player.less-480 .ppn-epg-float-anchor .channel-list .item div {
  width: 72px;
  height: 52px;
  padding: 0;
  gap: 0;
  border-radius: 8px;
  border-bottom: none;
}
.litv-player.less-480 .ppn-epg-float-anchor .channel-list .item.current div {
  border: solid 2px #ff6600;
  background: rgba(255, 255, 255, 0.1);
}
.litv-player.less-480 .ppn-epg-float-anchor .channel-list .item:hover div {
  background: rgba(255, 255, 255, 0.15);
}
.litv-player.less-480 .ppn-epg-float-anchor .channel-list .item img {
  width: 100%;
}
.litv-player.less-480 .ppn-epg-float-anchor .channel-list .item span,
.litv-player.less-480 .ppn-epg-float-anchor .channel-list .item svg {
  display: none;
}
.litv-player:not(.less-480) .ppn-epg-float-anchor .channel-list-wrapper .channel-scroll,
.ppn-epg-fixed-anchor .channel-list-wrapper .channel-scroll {
  display: none;
}
.litv-player:not(.less-480) .ppn-epg-float-anchor .channel-list .item.current,
.ppn-epg-fixed-anchor .channel-list .item.current {
  color: #fe7820;
  background: rgba(255, 255, 255, 0.1);
}
.litv-player:not(.less-480) .ppn-epg-float-anchor .channel-list .item:hover,
.ppn-epg-fixed-anchor .channel-list .item:hover {
  background: rgba(255, 255, 255, 0.15);
}
.ppn-content[data-epg-page='schedule'] .channel-schedule-wrapper {
  display: flex;
}
.ppn-container .channel-schedule-wrapper {
  display: none;
  position: absolute;
  flex-direction: column;
  align-items: center;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  color: white;
  background: #111;
}
.ppn-container .channel-schedule-wrapper .menu-header::before {
  content: attr(data-title);
}
.ppn-container .channel-schedule-wrapper .back-icon {
  cursor: pointer;
  position: absolute;
  left: 20px;
  top: 16px;
}
.ppn-container .channel-schedule {
  overflow-y: scroll;
}
.ppn-container .channel-schedule,
.ppn-container .channel-schedule .schedule-chunk {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ppn-container .channel-schedule::-webkit-scrollbar {
  width: 4px;
}
.ppn-container .channel-schedule::-webkit-scrollbar-thumb {
  background-color: grey;
  border-radius: 4px;
}
.ppn-container .channel-schedule .date,
.ppn-container .channel-schedule .program {
  width: 100%;
  box-sizing: border-box;
}
.ppn-container .channel-schedule .date {
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  height: 36px;
  border-top: solid 1px #dedede;
  border-bottom: solid 1px #dedede;
  font-size: 14px;
  background-color: #111;
}
.ppn-container .channel-schedule .date svg {
  margin: 0 16px;
}
.ppn-container .channel-schedule .program:hover {
  background: rgba(255, 255, 255, 0.15);
}
.ppn-container .channel-schedule .program.current {
  background: rgba(255, 255, 255, 0.1);
}
.ppn-container .channel-schedule .program span {
  font-size: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ppn-container .channel-schedule .program svg {
  display: none;
}
.ppn-container .channel-schedule[data-type="vod"] .program {
  display: flex;
  align-items: center;
  height: 52px;
  padding: 0 8px 0 16px;
  border-bottom: solid 1px grey;
  flex-shrink: 0;
}
.ppn-container .channel-schedule[data-type="vod"] .program span {
  padding-left: 24px;
}
.ppn-container .channel-schedule[data-type="vod"] .program.current span {
  padding-left: 16px;
  font-weight: bold;
  color: var(--brand-color);
}
.ppn-container .channel-schedule[data-type="vod"] .program.current::before {
  content: "";
  width: 8px;
  height: 8px;
  flex-shrink: 0;
  border-radius: 50%;
  background-color: var(--brand-color);
}
.ppn-container .channel-schedule[data-type="vod"] .program:last-of-type {
  border-bottom: none;
}
.ppn-container .channel-schedule[data-type="live"] .program {
  display: grid;
  grid-template-columns: 80px 1fr;
  height: 75px;
}
.ppn-container .channel-schedule[data-type="live"] .program .time {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-size: 14px;
}
.ppn-container .channel-schedule[data-type="live"] .program.current .time::before {
  content: "LIVE";
  font-size: 12px;
  padding: 2px 8px;
  border-radius: 2px;
  background: #dc3232;
}
.ppn-container .channel-schedule[data-type="live"] .program .title {
  display: flex;
  align-items: center;
  overflow: hidden;
  padding-right: 8px;
  border-bottom: solid 1px grey;
}
.ppn-container .channel-schedule[data-type="live"] .program:last-of-type .title {
  border-bottom: none;
}
.ppn-container .channel-schedule.selectable .program {
  cursor: pointer;
}
.ppn-container .channel-schedule.selectable .program:hover span {
  color: var(--brand-color);
}
.ppn-container .channel-schedule.selectable .program:hover svg {
  fill: var(--brand-color);
}
.ppn-container .channel-schedule.selectable .program svg {
  margin-left: auto;
  fill: white;
  flex-shrink: 0;
}
.ppn-container .channel-schedule.selectable .program:not(.current) svg.play_icon {
  display: block;
}
.ppn-container .channel-schedule.selectable .program.current svg.replay_icon {
  display: block;
}
.ppn-container .channel-schedule.selectable .program.current[data-type="vod"] svg.replay_icon {
  fill: var(--brand-color);
}
.ppn-content {
  --brand-color: red;
}
.ppn-content .epg-container {
  display: none;
}
.ppn-content .epg-close-area {
  z-index: 1;
}
.ppn-content .epg-close-area .close_icon {
  cursor: pointer;
}
.ppn-content .epg-menu-area {
  width: 100%;
  height: 100%;
}
.ppn-content .menu-header {
  height: 56px;
  line-height: 56px;
  flex-shrink: 0;
  overflow: hidden;
}
.ppn-content .menu-header::before {
  font-size: 16px;
}
.ppn-content[data-epg-style=""] {
  height: 0;
  overflow: hidden;
}
.ppn-content[data-epg-style="in"] {
  display: block;
}
.ppn-content[data-epg-style="in"] .vjs-schedule-button {
  display: block !important;
}
.ppn-content[data-epg-style="in"] .vjs-schedule-button:hover:after {
  content: attr(data-text);
}
.ppn-content[data-epg-style="in"][data-epg-page="channel"] .watch_more,
.ppn-content[data-epg-style="in"][data-epg-page="schedule"] .watch_more {
  display: none;
}
.ppn-content[data-epg-style="out"] {
  display: grid;
  grid-template-columns: max(500px, 60%) 1fr;
}
.litv-player .ppn-epg-float-anchor .epg-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
}
.ppn-content[data-epg-page='channel'] .litv-player .ppn-epg-float-anchor .epg-container,
.ppn-content[data-epg-page='schedule'] .litv-player .ppn-epg-float-anchor .epg-container {
  display: grid;
}
.litv-player .ppn-epg-float-anchor .epg-close-area {
  width: 100%;
  height: 100%;
}
.litv-player .ppn-epg-float-anchor .epg-menu-area {
  position: relative;
  box-shadow: -10px 0 10px -10px black;
}
.litv-player:not(.less-480) .ppn-epg-float-anchor .epg-container {
  grid-template-columns: 1fr 40%;
}
.litv-player:not(.less-480) .ppn-epg-float-anchor .epg-close-area .close_icon {
  position: absolute;
  top: 8px;
  right: 12px;
}
.litv-player.less-480 .ppn-epg-float-anchor .epg-container {
  grid-template-columns: 1fr 80px;
}
.litv-player.less-480 .ppn-epg-float-anchor .epg-close-area .close_icon {
  position: absolute;
  top: 0;
  right: 80px;
}
.ppn-epg-fixed-anchor .epg-container {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}
.ppn-epg-fixed-anchor .epg-close-area {
  display: none;
}
.ppn-container {
  width: 100%;
  position: relative;
}
.ppn-container div {
  margin: unset;
}
.ppn-container a {
  outline: none;
}
.ppn-content_wrapper {
  display: none;
  position: static;
}
.ppn-container[min-width~='730px'] .ppn-content_wrapper,
.ppn-container[max-width~='729px'] .ppn-content_wrapper {
  display: block;
}
.ppn-content.ppn-has_ancillary {
  position: relative;
  margin: 0 auto;
}
.ppn-content .ppn-playlist-playicon {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: content-box;
}
.ppn-content .ppn-playlist-playicon::before {
  content: "";
  display: block;
  height: 24px;
  width: 24px;
  border-radius: 13px;
  border: solid 1px #fff;
  background-image: linear-gradient(to bottom, rgba(207, 186, 229, 0.85), rgba(140, 69, 147, 0.85) 99%);
}
.ppn-content .ppn-playlist-playicon::after {
  content: "";
  display: block;
  position: absolute;
  top: 8px;
  left: 10px;
  border-style: solid;
  border-width: 5px 0px 5px 8px;
  border-color: transparent #fff;
}
.ppn-outer_player {
  background: #000;
}
.ppn-outer_ancillary {
  display: none;
}
.ppn-has_ancillary .ppn-outer_ancillary {
  position: relative;
  display: block;
  background: #f1f1f1;
  overflow: hidden;
}
.ppn-wrapper_player {
  width: 100%;
  background-color: #000;
  position: relative;
}
.ppn-player_main {
  position: absolute;
  top: 0;
  border-radius: 0;
  left: 50%;
  transform: translateX(-50%);
}
.ppn-container .ppn-ancillary {
  background: #f1f1f1;
  margin: 0 auto;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.ppn-container .ppn-ancillary::before {
  position: absolute;
  bottom: 2px;
  right: 2px;
  color: #fff;
}
.ppn-container .ppn-ancillary[data-state=Showcase]::before {
  content: "showcase";
}
.ppn-container .ppn-ancillary[data-state=CompanionAD]::before {
  content: "companion AD";
}
.ppn-sidebar.ppn-bottom {
  font-size: 16px;
  background-color: #000;
  position: relative;
  align-items: center;
  height: 41px;
  white-space: nowrap;
  display: none;
  font-weight: 400;
  width: 100%;
}
.ppn-has_bottom .ppn-sidebar.ppn-bottom {
  display: flex;
  justify-content: space-between;
}
.ppn-sidebar .ppn-title {
  font-size: 1.06em;
  color: #fff;
  text-decoration: none;
  text-align: justify;
  padding-left: 0.86em;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
}
.ppn-font-big .ppn-sidebar .ppn-title {
  font-size: 1.2em;
}
.ppn-font-tiny .ppn-sidebar .ppn-title {
  font-size: 0.9em;
}
.ppn-sidebar .ppn-logo {
  display: block;
  margin: 0 6px;
}
.ppn-sidebar .ppn-logo img {
  max-width: 150px;
  max-height: 32px;
  vertical-align: bottom;
}
.ppn-disable_logo .ppn-sidebar .ppn-logo {
  display: none;
}
.ppn-cut-out .ppn-sidebar .ppn-title::before {
  content: '\\5373\\5c07\\64ad\\653e\\ff1a';
  /*即將播放：*/
}
.ppn-container:not(.ppn-overflows) .ppn-has_bottom .litv-player:not(.is-fullscreen) [data-element-id="*litv_logo"] {
  display: none;
}
.ppn-container  a[href=''],
.ppn-container  a[href='javascript:void(0);'],
.ppn-container  a[href='data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=='] {
  pointer-events: none;
  cursor: default;
}
.ppn-overflows .litv-player .unmute {
  display: none !important;
}
.ppn-content.fullscreen .ppn-outer_ancillary,
.ppn-floating_enable.ppn-overflows .ppn-outer_ancillary,
.ppn-content.fullscreen .ppn-sidebar,
.ppn-floating_enable.ppn-overflows .ppn-sidebar {
  display: none !important;
}
.ppn-side_btn {
  position: absolute;
  text-decoration: none;
  color: #fff;
  display: block;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;
  width: 45px;
  height: 145px;
  border-radius: 28px 0 0 28px;
  border: solid 1.8px rgba(0, 0, 0, 0.7);
  background-color: rgba(0, 0, 0, 0.7);
  font-size: 24px;
}
.ppn-side_btn > div {
  width: 0.8em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.17;
  font-family: PingFangTC;
  font-weight: 500;
}
.ppn-side_btn:hover {
  background-color: #000;
}
.ppn-side_btn:hover > div {
  font-size: 1.2em;
}
.litv-player.is-small .ppn-side_btn {
  width: 40px;
  height: 122px;
  border-radius: 26px 0 0 26px;
  border: solid 1.7px rgba(0, 0, 0, 0.7);
  font-size: 22px;
}
.litv-player.is-tiny .ppn-side_btn {
  width: 24px;
  height: 70px;
  border-radius: 16px 0 0 16px;
  border: solid 1px rgba(0, 0, 0, 0.7);
  font-size: 12px;
}
.litv-player.linear_ad_impression .ppn-side_btn {
  display: none;
}
.ppn-content.ppn-error_mode .ppn-title {
  visibility: hidden;
}
.ppn-content.ppn-error_mode .ppn-side_btn {
  display: none;
}
.ppn-content.ppn-error_mode .logo_container::after {
  content: "\\7cbe\\5f69\\5f71\\7247\\ff0c\\5373\\5c07\\958b\\59cb\\ff01";
  position: absolute;
  bottom: 50%;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.65);
  padding: 5px;
  border-radius: 5px;
}
.ppn-pup_close_btn {
  opacity: 0;
  width: 0;
  height: 0;
  text-shadow: #000000 0px 1px 0px;
  text-align: center;
  vertical-align: middle;
  border-radius: 100%;
  box-sizing: content-box;
  position: absolute;
  top: 6px;
  left: 6px;
  cursor: pointer;
  z-index: 9;
  fill: rgba(255, 255, 255, 0.54);
  border: 1px solid #ffffff;
  background-color: rgba(0, 0, 0, 0.54);
}
.ppn-container.ppn-floating_enable.ppn-overflows .ppn-pup_close_btn {
  opacity: 1;
  width: 26px;
  height: 26px;
  transition-property: width, height, opacity;
  transition-delay: 3s;
}
.ppn-container.ppn-floating_enable.ppn-overflows .ppn-pup_close_btn:hover {
  fill: #ffffff;
  border: 1px solid #ffffff;
  background-color: #000000;
}
.ppn-pup_close_btn div {
  width: 8px;
  height: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ppn-pup_close_btn div svg {
  position: absolute;
  top: 0;
  left: 0;
}
.ppn-pup_close_btn.hide {
  opacity: 0 !important;
}
.ppn-container.ppn-floating_enable.ppn-overflows .ppn-content_wrapper {
  position: fixed !important;
  z-index: 99999;
  width: 315px;
  height: 178px;
}
@media (max-width: 600px) {
  .ppn-container.ppn-floating_enable.ppn-overflows .ppn-content_wrapper {
    width: 240px;
    height: 135px;
  }
  .ppn-container.ppn-floating_enable.ppn-overflows .ppn-content_wrapper .litv-player .video-js {
    font-size: 12px;
  }
}
.ppn-br.ppn-container.ppn-floating_enable.ppn-overflows .ppn-content_wrapper {
  top: auto;
  left: auto;
  bottom: 110px;
  right: 20px;
}
.ppn-tl.ppn-container.ppn-floating_enable.ppn-overflows .ppn-content_wrapper {
  top: 55px;
  left: 20px;
  bottom: auto;
  right: auto;
}
.ppn-tr.ppn-container.ppn-floating_enable.ppn-overflows .ppn-content_wrapper {
  top: 55px;
  left: auto;
  bottom: auto;
  right: 20px;
}
.ppn-bl.ppn-container.ppn-floating_enable.ppn-overflows .ppn-content_wrapper {
  top: auto;
  left: 20px;
  bottom: 110px;
  right: auto;
}
.ppn-tc.ppn-container.ppn-floating_enable.ppn-overflows .ppn-content_wrapper {
  top: 55px;
  left: 50%;
  bottom: auto;
  right: auto;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.ppn-bc.ppn-container.ppn-floating_enable.ppn-overflows .ppn-content_wrapper {
  top: auto;
  left: 50%;
  bottom: 110px;
  right: auto;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.ppn-mr.ppn-container.ppn-floating_enable.ppn-overflows .ppn-content_wrapper {
  top: 50%;
  left: auto;
  bottom: auto;
  right: 20px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.ppn-ml.ppn-container.ppn-floating_enable.ppn-overflows .ppn-content_wrapper {
  top: 50%;
  left: 20px;
  bottom: auto;
  right: auto;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.ppn-mc.ppn-container.ppn-floating_enable.ppn-overflows .ppn-content_wrapper {
  top: auto;
  left: auto;
  bottom: auto;
  right: auto;
  -webkit-transform: translate(-50%);
  -ms-transform: translate(-50%);
  transform: translate(-50%);
}
.ppn-placeholder {
  display: none;
  width: 0px;
}
.ppn-container.ppn-floating_enable.ppn-overflows .ppn-placeholder {
  display: block;
}
.ppn-exf {
  display: none;
  margin: 0;
  padding: 0;
}
.litv-player .caption {
  top: 0;
}
.ppn-container[min-width~='730px']:not(.ppn-overflows) .ppn-has_ancillary {
  display: grid;
  grid: 1fr / max(500px, 60%) 1fr;
}
.ppn-container[min-width~='730px']:not(.ppn-overflows) .ppn-has_ancillary.ppn-has_bottom {
  padding-bottom: 41px;
}
.ppn-container[min-width~='730px']:not(.ppn-overflows) .ppn-has_ancillary .ppn-sidebar.ppn-bottom {
  position: absolute;
  bottom: 0;
}
.ppn-container[max-width~='729px']:not(.ppn-overflows) .ppn-has_ancillary {
  display: block;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
