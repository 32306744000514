import { fetchRpc } from "./requestHelper.js";
import { ENV } from "./Util.js";

const RPC_URL = "https://lookme.club.tw:8885/ppn/log";
const RPC_METHOD = "LiAdPPN.Log";
const VERSION = "1";

function getLogRate(config) {
    if (ENV.current != ENV.PRODUCTION) {
        return 1;
    }
    if (window.ppn_log_rate?.[config.partnerName] != null) {
        return window.ppn_log_rate[config.partnerName] / 100;
    }
    return 0.01;
}

export default function monkeyTracing(config, meta) {
    const LOG_RATE = getLogRate(config);
    const puid = config.puid;
    const serviceId = ENV.current;
    const model = navigator.userAgent;

    return {
        send: (eventType, eventData = {}) => {
            if (Math.random() < LOG_RATE) {
                fetchRpc(RPC_URL, RPC_METHOD, {
                    puid: puid,
                    event: eventType,
                    model: model,
                    view: document.title,
                    service_id: serviceId,
                    event_data: JSON.stringify({
                        ...eventData,
                        assetId: meta.assetId,
                        mediaType: meta.mediaType,
                        title: meta.title,
                        secondaryTitle: meta.secondaryTitle,
                    }),
                    ver: VERSION,
                });
            }
        },
    };
}
