import closeBtnSvg from "@images/ppnio_close_btn.svg";

import { getElement } from "../Util/Util.js";
import { createPlayer, sendInitTracking, sendImpressionTracking } from "../Util/MainviewHelper.js";

import Meta from "./Meta.js";
import Tracing from "../Util/Tracing.js";
import ContentManager from "./ContentManager.js";
import StickyControl from "../Component/StickyControl.js";
import Epg from "./Epg.js";
import ObserverPattern from "../Util/ObserverPattern.js";
import Event from "./Event.js";
import watchMoreButton from "./WatchMoreButton.js";
import WatchLog from "./WatchLog.js";
import ViewportSensor from "./ViewPortSensor.js";

export const fabric = `
<div class="ppn-content_wrapper">
    <div class="ppn-content" data-epg-style="">
        <div class="ppn-outer_player">
            <div class="ppn-wrapper_player ppn-ratio-16-9">
                <div class="ppn-player_main"></div>
            </div>
        </div>
    </div>
    <div class="ppn-pup_close_btn hide"><div>${closeBtnSvg}</div></div>
</div>
<div class="ppn-placeholder"></div>
`;

export default async function MainView(ppnId, $container, config, exApi) {
    let eventCenter = new ObserverPattern();
    let meta = new Meta(config, eventCenter);
    let player = createPlayer($container, config);
    let tracing = new Tracing(config, meta, player, exApi);
    let $content = getElement($container, ".ppn-content");
    let viewPortSensor = ViewportSensor($content, eventCenter);
    ContentManager($content, config, meta, player, eventCenter, tracing);

    try {
        await meta.init;

        if (config.enableFloating) {
            StickyControl($container, $content, config, player, tracing);
        }
        if (config.enableSideBtn) {
            watchMoreButton(player, config);
        }
        if (config.enableWatchLog) {
            WatchLog(config, meta, eventCenter, viewPortSensor);
        }
        Epg($content, meta, player, eventCenter, tracing);

        let channelIdOb = new MutationObserver(() => {
            eventCenter.trigger(Event.PLAY, { channelIndex: getChannelIndex($container, meta) });
        });
        channelIdOb.observe($container, { attributes: true, attributeFilter: ["data-channel-id"] });
        eventCenter.trigger(Event.PLAY, { channelIndex: getChannelIndex($container, meta) });
    } catch (e) {
        console.log(e);
    }
    sendInitTracking(config, tracing);
    eventCenter.one(Event.VIEW_IN, () => sendImpressionTracking(tracing));

    viewPortSensor.start();
    window.addEventListener("unload", () => eventCenter.trigger(Event.Dismiss));
}

function getChannelIndex(container, meta) {
    let channelId = container.dataset.channelId;
    if (channelId == null) {
        return 0;
    }
    let channelIndex = meta.getChannelIndex(channelId);
    if (channelIndex < 0) {
        return 0;
    }
    return channelIndex;
}
