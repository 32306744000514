export default {
    VIEW_IN: "view_in",
    VIEW_OUT: "view_out",
    EpgChannel: "epg_channel", // signal for channel to render list.
    EpgSchedule: "epg_schedule", // signal for schedule to render list.
    EpgOpen: "epg_open", // signal for open epg.
    EpgClose: "epg_close", // signal for close epg.
    ChannelChange: "channel_change", // signal when channel change.
    ProgramChange: "program_change", // signal when current channel starts or plays new program, or other channels' on air program change.
    PLAY: "play", // a CMD for contentManager to start playing process.
    Dismiss: "dismiss", // sign to stop listeners for all components
};
