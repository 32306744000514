import { WatchLogPost } from "../Api/PpnCdi";
import Event from "./Event";

const POST_INTERVAL = 30e3;

export default function WatchLog(config, meta, eventCenter, viewPortSensor) {
    let postLog = WatchLogPost(config, meta);
    let eventId = null;

    eventCenter.on(Event.ChannelChange, onChannelChange);
    window.addEventListener("unload", stop);

    function onChannelChange() {
        stop();
        if (viewPortSensor.inViewport) {
            return start();
        }
        eventCenter.on(Event.VIEW_IN, start);
    }

    function start() {
        eventCenter.off(Event.VIEW_IN, start);
        postLog();
        eventId = setInterval(postLogChance, POST_INTERVAL);
    }

    function postLogChance() {
        if (viewPortSensor.inViewport) {
            postLog();
        }
    }

    function stop() {
        eventCenter.off(Event.VIEW_IN, start);
        if (eventId != null) {
            clearInterval(eventId);
            eventId = null;
        }
    }
}
